import React, { useState, useEffect } from "react";
import axios from "axios";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";

const AdminMap = () => {
  const [players, setPlayers] = useState([]);
  const [error, setError] = useState(null);

  // Récupération des données
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/allPlayers`
        );
        setPlayers(response.data.devices);
      } catch (err) {
        console.error("Erreur lors de la récupération des players :", err);
        setError("Impossible de récupérer les données.");
      }
    };

    fetchPlayers();
  }, []);

  // Initialisation de la carte
  useEffect(() => {
    const map = L.map("map", {
      center: [46.603354, 1.888334], // Centre de la France
      zoom: 6,
      layers: [
        L.tileLayer(
          "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png", // Carto Light
          {
            attribution: '&copy; <a href="https://www.carto.com/">CARTO</a>',
          }
        ),
      ],
    });

    const markers = L.markerClusterGroup({
      maxClusterRadius: 50, // Ajuster la distance de regroupement si nécessaire
      iconCreateFunction: function (cluster) {
        const childMarkers = cluster.getAllChildMarkers();

        // Récupérer les statuts des appareils
        const areAllOnline = childMarkers.every(
          (marker) => marker.options.isOnline
        );
        const areAllOffline = childMarkers.every(
          (marker) => !marker.options.isOnline
        );

        // Déterminer la couleur du cluster
        let color;
        if (areAllOnline) {
          color = "green"; // Tous en ligne
        } else if (areAllOffline) {
          color = "red"; // Tous hors ligne
        } else {
          color = "orange"; // Mixte
        }

        // Créer l'icône avec la couleur déterminée
        return L.divIcon({
          html: `<div style="background-color: ${color}; color: white; border-radius: 50%; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center;">
                  ${cluster.getChildCount()}
                 </div>`,
          className: "custom-cluster-icon",
          iconSize: L.point(40, 40, true),
        });
      },
    });

    players.forEach((player) => {
      if (player.coordinates) {
        const isOnline = new Date() - new Date(player.lastOnline) <= 60000; // Dernière activité < 60s
        const color = isOnline ? "green" : "red";

        const marker = L.circleMarker(
          [player.coordinates.latitude, player.coordinates.longitude],
          {
            color,
            fillColor: color,
            fillOpacity: 0.8,
            radius: 10,
          }
        ).bindTooltip(
          `<strong>${player.name}</strong><br/>Statut : ${
            isOnline ? "En ligne" : "Hors ligne"
          }<br/>Dernière activité : ${
            player.lastOnline
              ? new Date(player.lastOnline).toLocaleString()
              : "Inconnue"
          }`
        );

        markers.addLayer(marker);
      }
    });

    map.addLayer(markers);
    return () => {
      map.remove();
    };
  }, [players]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Carte des players</h1>
      {error && <p className="text-red-500">{error}</p>}
      <div id="map" style={{ height: "500px", width: "100%" }} />
    </div>
  );
};

export default AdminMap;
