import React, { useState } from "react";
import {
  FaDesktop,
  FaImages,
  FaThList,
  FaFileInvoice,
  FaCreditCard,
  FaCog,
  FaUser,
  FaChartLine,
  FaChevronDown,
  FaChevronRight,
  FaMap,
} from "react-icons/fa";
import { useUser } from "@clerk/clerk-react";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const Sidebar = ({ setActiveComponent }) => {
  const [activeTab, setActiveTab] = useState("myPlayers");
  const [isAdminExpanded, setIsAdminExpanded] = useState(false);
  const { user } = useUser();

  const buttonStyle =
    "flex items-center gap-2 p-2 w-full text-left rounded-lg text-slate-600 transition-colors duration-200 ease-in-out cursor-pointer hover:bg-gray-100 focus:bg-gray-100 focus:outline-none";
  const activeButtonStyle = "bg-gray-200 rounded";
  const iconStyle = "w-5 h-5 mx-auto md:mx-0";
  const textClass = "hidden md:block";

  const handleSetActiveComponent = (componentName) => {
    console.log("[Sidebar] Changing active component to:", componentName);
    setActiveComponent(componentName);
    setActiveTab(componentName);
  };

  const redirectToCustomerPortal = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/stripe/create-customer-portal-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clerkUserId: user.id,
          }),
        }
      );
      const data = await response.json();

      if (response.ok) {
        window.open(data.url, "_blank");
      } else {
        console.error("Erreur lors de la redirection:", data.error);
      }
    } catch (error) {
      console.error("Erreur lors de la redirection:", error);
    }
  };

  return (
    <div className="bg-white h-full flex-grow pt-10 w-16 pl-2 pr-2 md:w-64 flex flex-col justify-between border-r border-gray-300 transition-all duration-300 ease-in-out">
      <div>
        <nav>
          <div
            onClick={() => handleSetActiveComponent("myPlayers")}
            className={`${buttonStyle} ${
              activeTab === "myPlayers" ? activeButtonStyle : ""
            }`}
          >
            <FaDesktop className={iconStyle} />
            <span className={textClass}>Écrans</span>
          </div>

          <div
            onClick={() => handleSetActiveComponent("myMedias")}
            className={`${buttonStyle} ${
              activeTab === "myMedias" ? activeButtonStyle : ""
            }`}
          >
            <FaImages className={iconStyle} />
            <span className={textClass}>Médias</span>
          </div>

          <div
            onClick={() => handleSetActiveComponent("myPlaylists")}
            className={`${buttonStyle} ${
              activeTab === "myPlaylists" ? activeButtonStyle : ""
            }`}
          >
            <FaThList className={iconStyle} />
            <span className={textClass}>Playlists</span>
          </div>

          {user?.id === process.env.REACT_APP_ADMIN_CLERK_ID && (
            <div>
              <div
                onClick={() => setIsAdminExpanded(!isAdminExpanded)}
                className={`${buttonStyle}`}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center gap-2">
                    <FaCog className={iconStyle} />
                    <span className={textClass}>Administration</span>
                  </div>
                  <div>
                    {isAdminExpanded ? (
                      <FaChevronDown className="w-4 h-4" />
                    ) : (
                      <FaChevronRight className="w-4 h-4" />
                    )}
                  </div>
                </div>
              </div>

              {isAdminExpanded && (
                <div className="ml-4">
                  <div
                    onClick={() => handleSetActiveComponent("adminFinances")}
                    className={`${buttonStyle} ${
                      activeTab === "adminFinances" ? activeButtonStyle : ""
                    }`}
                  >
                    <FaChartLine className={iconStyle} />
                    <span className={textClass}>Finances</span>
                  </div>
                  <div
                    onClick={() => handleSetActiveComponent("adminPlayers")}
                    className={`${buttonStyle} ${
                      activeTab === "adminPlayers" ? activeButtonStyle : ""
                    }`}
                  >
                    <FaDesktop className={iconStyle} />
                    <span className={textClass}>Players</span>
                  </div>
                  <div
                    onClick={() => handleSetActiveComponent("adminUsers")}
                    className={`${buttonStyle} ${
                      activeTab === "adminUsers" ? activeButtonStyle : ""
                    }`}
                  >
                    <FaUser className={iconStyle} />
                    <span className={textClass}>Utilisateurs</span>
                  </div>
                  <div
                    onClick={() => handleSetActiveComponent("adminMap")}
                    className={`${buttonStyle} ${
                      activeTab === "adminMap" ? activeButtonStyle : ""
                    }`}
                  >
                    <FaMap className={iconStyle} /> {/* Icône de carte */}
                    <span className={textClass}>Carte</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </nav>
      </div>

      <div className="mb-3">
        <nav>
          <div
            onClick={() => handleSetActiveComponent("myInvoices")}
            className={`${buttonStyle} ${
              activeTab === "myInvoices" ? activeButtonStyle : ""
            }`}
          >
            <FaFileInvoice className={iconStyle} />
            <span className={textClass}>Factures</span>
          </div>
          <div onClick={redirectToCustomerPortal} className={`${buttonStyle}`}>
            <FaCreditCard className={iconStyle} />
            <span className={textClass}>Abonnement</span>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
