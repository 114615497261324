import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const isDebug = process.env.REACT_APP_DEBUG === "true";

const logDebug = (...args) => {
  if (isDebug) {
    console.log(...args);
  }
};

const WebPlayer = () => {
  const { deviceId } = useParams();
  const [playlist, setPlaylist] = useState(null);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [isReplaying, setIsReplaying] = useState(false);
  const intervalRef = useRef(null);
  const videoRef = useRef(null);
  const imageTimeoutRef = useRef(null);
  const lastPlaylistRef = useRef(null);

  /**
   * Récupère la playlist depuis l'API.
   * Met à jour la playlist si elle a changé, sinon continue la lecture actuelle.
   */
  const fetchPlaylist = async () => {
    logDebug("Fetching playlist...");
    try {
      const response = await axios.get(
        `${apiUrl}/playlist/web-player/${deviceId}`
      );
      if (
        response.data &&
        response.data.mediaItems &&
        response.data.mediaItems.length > 0
      ) {
        const activePlaylist = response.data;
        logDebug("Playlist fetched:", activePlaylist);

        // Vérifie si la playlist a changé
        if (
          !lastPlaylistRef.current ||
          JSON.stringify(lastPlaylistRef.current) !==
            JSON.stringify(activePlaylist.mediaItems)
        ) {
          logDebug("Playlist has changed or is new. Updating playlist...");
          lastPlaylistRef.current = activePlaylist.mediaItems;
          setPlaylist(activePlaylist);

          if (currentMediaIndex >= activePlaylist.mediaItems.length) {
            logDebug(
              "Current media index exceeds playlist length. Resetting index to 0."
            );
            setCurrentMediaIndex(0);
          }

          if (activePlaylist.mediaItems.length > 0) {
            loadMedia(activePlaylist.mediaItems[0]);
          } else {
            setMediaUrl(null);
          }
        } else {
          logDebug("Playlist has not changed. Continuing playback.");
        }
      } else {
        logDebug("No active playlist available for this device.");
        setPlaylist(null);
        setMediaUrl(null);
      }
    } catch (error) {
      logDebug("Erreur lors de la récupération de la playlist :", error);
    }
  };

  /**
   * Charge un média spécifique.
   * @param {Object} mediaItem - L'item média à charger (video ou image).
   */
  const loadMedia = async (mediaItem) => {
    if (!mediaItem) {
      logDebug("No media item provided to loadMedia");
      return;
    }

    try {
      const { userId, fileName } = mediaItem;
      logDebug("Loading media:", fileName);

      const response = await axios.get(
        `${apiUrl}/media/view/${userId}/${fileName}`
      );
      const signedUrl = response.data.url;

      if (mediaUrl !== signedUrl) {
        logDebug("Updating media URL.");
        setMediaUrl(signedUrl);
        setIsReplaying(false);
      } else {
        logDebug("Media URL is the same, no need to reload.");
      }
    } catch (error) {
      logDebug(`Error loading media URL for ${mediaItem.fileName}:`, error);
      setMediaUrl(null);
      handleMediaEnd();
    }
  };

  /**
   * useEffect pour charger la playlist initiale et la rafraîchir régulièrement.
   */
  useEffect(() => {
    if (deviceId) {
      fetchPlaylist();
    }

    intervalRef.current = setInterval(() => {
      logDebug("Refreshing playlist...");
      fetchPlaylist();
    }, 3000);

    return () => {
      logDebug("Clearing intervals and timeouts on component unmount.");
      clearInterval(intervalRef.current);
      clearTimeout(imageTimeoutRef.current);
    };
  }, [deviceId]);

  /**
   * useEffect pour charger le média lorsque la playlist ou l'index courant change.
   */
  useEffect(() => {
    if (playlist && playlist.mediaItems.length > 0) {
      const currentMedia = playlist.mediaItems[currentMediaIndex];
      logDebug("Continuing playback of current media:", currentMedia.fileName);
      loadMedia(currentMedia);
    }
  }, [playlist, currentMediaIndex]);

  /**
   * Gère la fin du média:
   * - Si une seule vidéo, on la rejoue en boucle.
   * - Si une seule image, on la réaffiche.
   * - Si plusieurs médias, on passe au suivant.
   */
  const handleMediaEnd = () => {
    if (playlist && playlist.mediaItems.length > 0) {
      if (playlist.mediaItems.length === 1) {
        // Relecture en boucle du même média
        logDebug("Replaying single media in playlist.");
        const singleMedia = playlist.mediaItems[0];
        if (singleMedia.mediaType === "video" && videoRef.current) {
          setIsReplaying(true);
          logDebug("Resetting video to start and replaying...");

          // Arrêter la vidéo, réinitialiser le playbackRate, remettre à zéro, puis rejouer
          videoRef.current.pause();
          videoRef.current.defaultPlaybackRate = 1;
          videoRef.current.playbackRate = 1;
          videoRef.current.currentTime = 0;

          videoRef.current
            .play()
            .then(() => {
              applyFadeIn();
              logDebug("Video replayed successfully at normal speed.");
            })
            .catch((error) => {
              logDebug("Error replaying video:", error.message);
            });
        } else {
          // Si c'est une image, on boucle simplement dessus
          setCurrentMediaIndex(0);
          handleImageLoad(10);
        }
      } else {
        // Passage au média suivant si plusieurs médias
        logDebug("Moving to next media in playlist.");
        const nextIndex = (currentMediaIndex + 1) % playlist.mediaItems.length;
        setCurrentMediaIndex(nextIndex);
      }
    }
  };

  /**
   * Affiche les images pendant un temps donné avant de passer au média suivant.
   * @param {number} duration - Durée d'affichage en secondes (par défaut 10s).
   */
  const handleImageLoad = (duration = 10) => {
    logDebug(`Image loaded. Displaying for ${duration} seconds.`);
    applyFadeIn();
    imageTimeoutRef.current = setTimeout(handleMediaEnd, duration * 1000);
  };

  /**
   * Gère le double clic pour passer en plein écran.
   */
  const handleDoubleClick = () => {
    logDebug("Handling double-click for fullscreen...");
    try {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        logDebug("Exiting fullscreen mode.");
      } else if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
        logDebug("Entering fullscreen mode.");
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
        logDebug("Entering fullscreen mode (Webkit).");
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
        logDebug("Entering fullscreen mode (Moz).");
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
        logDebug("Entering fullscreen mode (MS).");
      } else {
        throw new Error("Fullscreen API is not supported");
      }
    } catch (error) {
      logDebug("Failed to enter fullscreen:", error.message);
    }
  };

  /**
   * useEffect pour jouer la vidéo quand l'URL du média change.
   * On gère manuellement la lecture sans utiliser autoPlay.
   */
  useEffect(() => {
    if (mediaUrl && videoRef.current) {
      logDebug("Preparing video playback at normal speed...");
      videoRef.current.pause();
      videoRef.current.defaultPlaybackRate = 1;
      videoRef.current.playbackRate = 1;
      videoRef.current.src = mediaUrl;
      videoRef.current.currentTime = 0;

      videoRef.current
        .play()
        .then(() => {
          applyFadeIn();
          logDebug("Video started at normal speed.");
        })
        .catch((error) => {
          logDebug("Error playing video after load:", error.message);
        });
    }
  }, [mediaUrl, isReplaying]);

  /**
   * Applique un effet de fondu sortant (fade-out) avant de changer de média.
   */
  const applyFadeOut = (callback) => {
    const mediaElement = videoRef.current || document.querySelector("img");
    if (mediaElement) {
      logDebug("Applying fade-out transition.");
      mediaElement.style.transition = "opacity 1s ease-in-out";
      mediaElement.style.opacity = 0;
      setTimeout(() => {
        if (callback) callback();
      }, 1000);
    }
  };

  /**
   * Applique un effet de fondu entrant (fade-in) après le changement de média.
   */
  const applyFadeIn = () => {
    const mediaElement = videoRef.current || document.querySelector("img");
    if (mediaElement) {
      logDebug("Applying fade-in transition.");
      mediaElement.style.transition = "opacity 1s ease-in-out";
      mediaElement.style.opacity = 1;
    }
  };

  /**
   * useEffect pour réappliquer un fade-in quand le mediaUrl change.
   */
  useEffect(() => {
    if (mediaUrl) {
      applyFadeIn();
    }
  }, [mediaUrl]);

  return (
    <div
      className="bg-black h-screen flex justify-center items-center"
      onDoubleClick={handleDoubleClick}
    >
      {playlist && playlist.mediaItems.length > 0 ? (
        <div className="w-full h-full">
          {playlist.mediaItems[currentMediaIndex]?.mediaType === "video" ? (
            <video
              ref={videoRef}
              // On ne met plus autoPlay ici, on gère la lecture dans les useEffect.
              muted
              onEnded={handleMediaEnd}
              onError={(e) => {
                logDebug("Error with video playback:", e.target.error);
                handleMediaEnd();
              }}
              style={{ width: "100%", height: "100%" }}
              className="object-contain"
              controls={false}
              disablePictureInPicture
              controlsList="nodownload nofullscreen noremoteplayback"
              onTimeUpdate={(e) => {
                const currentTime = e.target.currentTime;
                const duration = e.target.duration;
                // On peut appliquer un fade-out juste avant la fin si plusieurs médias.
                if (
                  duration - currentTime < 0.5 &&
                  playlist.mediaItems.length > 1
                ) {
                  applyFadeOut();
                }
              }}
            />
          ) : (
            <img
              src={mediaUrl}
              alt={playlist.mediaItems[currentMediaIndex]?.fileName || "Media"}
              className="object-contain w-full h-full"
              onLoad={() => {
                applyFadeIn();
                handleImageLoad();
              }}
            />
          )}
        </div>
      ) : (
        <div className="text-white">Aucun média à afficher.</div>
      )}
    </div>
  );
};

export default WebPlayer;
